import React, {Fragment} from 'react';
import {Redirect, Route, Switch, useHistory} from "react-router-dom";
import {Workbench} from './work';
import {Setting} from './setting';
import {UserAuth} from './setting/userauth';
import {UserName} from './setting/username';
import {Mobile} from './setting/mobile';
import {Password} from './setting/password';
import {AccountHeader} from './components';
import {useIsLogin} from '../../hook';
import {EmptyBox, Footer, TopHeader} from '../../components';
import {Project} from "./project";
import {Collect} from "./collect/collect";
import {Deposit} from "./deposit";
import {DepositDetail} from "./deposit/depositDetail";

export const Account = ()=>{

    let { push } = useHistory();
    const [isLogin] = useIsLogin();
    return (
        <Fragment>
            <TopHeader/>
            <AccountHeader/>
            <div className="container">
                <Switch>
                    {
                        isLogin ?
                            <Switch>
                                <Redirect exact from="/account" to="/account/setting"/>
                                <Route exact path="/account/work" component={Workbench}/>
                                <Route exact path="/account/deposit" component={Deposit}/>
                                <Route exact path="/account/deposit/detail" component={DepositDetail}/>
                                <Route exact path="/account/setting" component={Setting}/>
                                <Redirect exact path="/account/project" to='/account/project/list'/>
                                <Route exact path="/account/project/list" component={Project}/>
                                <Route exact path="/account/setting/userauth" component={UserAuth}/>
                                <Route exact path="/account/setting/username" component={UserName}/>
                                <Route exact path="/account/setting/password" component={Password}/>
                                <Route exact path="/account/setting/mobile" component={Mobile}/>
                                <Route exact path="/account/collect" component={Collect}/>
                                <Route exact path="/account/collect" component={Collect}/>
                                <Route key="error" render={(props) => <EmptyBox/>}/>
                            </Switch> :
                            <Route key="error"
                                   render={(props) => <EmptyBox onClick={() => push('/login')} message='您还没有登录'
                                                                btnText="登录"/>}/>
                    }

                </Switch>
            </div>
            <Footer/>
        </Fragment>
    );
}