import React, {useEffect, useState} from 'react';
import {Card, StatusWarp, SubNav, TypeWarp, UserInfo} from '../components';
import {YcPagination} from '../../../components';
import classNames from 'classnames';
import {DatePicker, Form, Input, Modal, message, Select} from 'antd';
import {useDataApi, useUserInfo} from '../../../hook';
import {useHistory} from "react-router-dom";
import css from './index.module.less';
import {createFromIconfontCN, DownOutlined, EditOutlined} from '@ant-design/icons'
import {ProModal} from "../../../common";
import IconEmpty from 'aliasimgurl/icon_empty.png';

const {Search} = Input;
const {Option} = Select;

const IconFont = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1696985_l6haxcqr03.js',
});

export const Project = (props) => {

    const {push} = useHistory();
    const [params, setParams] = useState({
        page: 1,
        type: '0',
        total: 0,
        project_name: props.location.state ? props.location.state.project_sn : ''
    }); //type '1' 招标项目 '2' 采购项目 '3'投标项目 '4' 采购报价

    const [form] = Form.useForm();
    const [{data: dataSource,setData, isLoading/*, isError */}, updateParams] = useDataApi();
    useEffect(() => {
        const {project_sn = '', type = '0'} = props.location.state || {};

        let _params = params;
        _params.project_name = project_sn;
        _params.type = type;
        setParams(_params);
    }, [props.location.state]);
    useEffect(() => {
        if (!isLoading) {
            const { total} = dataSource;
            const _params = params;
            _params.total = total;
            setParams(_params);
        }
    }, [isLoading]);
    useEffect(() => {
        const {project_name:search_name,..._params} = params;
        updateParams({
            url: '/pro/myProjectList',
            method: 'post',
            data: {search_name,..._params}
        })
    }, [params]);

    const handleTypeChange = (value) => {
        let _params = params;
        _params.type = value;
        setParams({..._params});

    };

    const handleStatusChange = (value)=>{
        let _params = params;
        _params.status = value;
        setParams({..._params});
    };

    const handleDateChange = (value)=>{
        setParams({...params,...value});
    }

    const handleSearch = (value) => {
        let _params = params;
        _params.project_name = value;
        _params.page = 1;
        setParams({..._params});
    };

    function projectNameChange(e) {
        params.project_name = e.target.value;
    }

    const goDetail = (opt)=>{
        const {id} = opt;
        push({pathname:'/obj/view/'+id});
    };
    const [{data: apply,isLoading:applyLoading}] = useDataApi();

    useEffect(()=>{
        if(!applyLoading){
            form.setFieldsValue({
                money:apply.money,
                store_user_name:apply.store_user_name,
                store_user_phone:apply.store_user_phone,
                remark:apply.store_remark,
            })
        }

    },[apply,applyLoading]);

    const handleChange = (opt,v,index)=>{
        const {id} = opt;
        ProModal.addPro({id,stage_id:v,status_type:1}).then(({data: {msg, code,data}}) => {
            if (code === 200) {
                message.success("修改成功");

            } else {
                message.error(msg)
            }
        })
    };

    const updateStatus = (opt,v,index)=>{
        const {id} = opt;
        ProModal.addPro({id,status:v,status_type:1}).then(({data: {msg, code,data}}) => {
            if (code === 200) {
                const _data = dataSource;
                _data.list[index].status = v;
                setData({..._data});
                message.success("修改成功");

            } else {
                message.error(msg)
            }
        })
    };
    const [userInfo] = useUserInfo();
    const addPro = ()=>{
        const {apply_state} = userInfo;
        if(apply_state !== 2){
            Modal.warning({
                title:'温馨提示',
                content: '您还未认证，是否现在认证？',
                okText:'去认证',
                cancelText:'取消',
                maskClosable: true,
                onOk: ()=>{
                    window.location.href = '/account/setting/userauth'
                },
                onCancel:()=>{

                }
            });
        }else{
            push({pathname:'/addproject',state:{}})
        }
    }
    return (
        <div className={css.body}>
            <div className={css.sideBar}>
                <UserInfo/>
                <SubNav/>
            </div>
            <div className={css.mainBody}>

                <Card title="项目管理" cardStyle={{padding: '20px 0px'}} extra={<div className={css.pubBtn} onClick={addPro}>  <IconFont  type={'icon-edit'}/><span>&nbsp;发布项目</span></div>}>
                    <Form.Item labelCol={{span:2}} wrapperCol={{span: 10}} label={'标题'}  style={{padding: '0 20px'}}>
                        <Search
                            placeholder="请输入项目名称/项目编号进行搜索"
                            enterButton="搜索"
                            defaultValue={params.project_name}
                            onChange={projectNameChange}
                            onSearch={value => handleSearch(value)}
                        />
                    </Form.Item>
                    <Form.Item label={'更新时间'} labelCol={{span:2}} wrapperCol={{span: 18}} style={{padding: '0 20px'}}>
                        <Form.Item style={{display: 'inline-block','marginBottom':'0'}} >
                            <DatePicker  format="YYYY-MM-DD" onChange={(v)=>handleDateChange({starttime: v.format('YYYY-MM-DD')})}/>
                        </Form.Item>
                        <span style={{textAlign: 'center', lineHeight: '25px',color:'#999'}}>&nbsp;&nbsp;-&nbsp;&nbsp;</span>
                        <Form.Item style={{display: 'inline-block','marginBottom':'0'}} >
                            <DatePicker format="YYYY-MM-DD" onChange={(v)=>handleDateChange({endtime: v.format('YYYY-MM-DD')})}/>
                        </Form.Item>
                    </Form.Item>
                    <Form.Item label={'项目类别'} labelCol={{span:2}} wrapperCol={{span: 12}} style={{padding: '0 20px'}}>
                        <TypeWarp onChange={handleTypeChange}/>
                    </Form.Item>

                    <Form.Item label={'项目状态'} labelCol={{span:2}} wrapperCol={{span: 18}}  style={{padding: '0 20px'}}>
                        <StatusWarp onChange={handleStatusChange}/>
                    </Form.Item>
                    <div className={classNames(css.header)}>
                        <div className={css.name}>项目详情</div>
                        <div >更新时间</div>
                        <div >状态</div>
                        <div className={css.operation} style={{width:'160px'}}>操作</div>
                    </div>
                    <div className={css.list}>
                        {!isLoading && dataSource.list.map((opt,index) =>
                            // <div className={css.item} key={opt.project_sn}>
                            <div className={css.item} key={index}>
                                <div className={css.title} >
                                   <div className={css.name} title={opt.name} onClick={()=>goDetail(opt)}> {opt.name || '未命名'}</div>
                                    { opt.status !==5 &&
                                    <Select className={css.select}
                                            placeholder="请选择"
                                             defaultValue={ opt.stage_id}
                                            onChange={ v=>handleChange(opt,v,index) }
                                            suffixIcon={<EditOutlined />}>

                                        <Option key={ 1 } value={ 1 } >发起阶段</Option>
                                        <Option key={ 2 } value={ 2 } >采购阶段</Option>
                                        <Option key={ 3 } value={ 3 } >执行阶段</Option>
                                        <Option key={ 4 } value={ 4 } >移交阶段</Option>
                                    </Select>}
                                </div>
                                <div className={classNames(css.content)}>
                                    <div style={{flex: '1'}}>
                                        <div className={css.other}>
                                            <div>所属地区：{opt.address}</div>
                                            <div><span>项目类型：{
                                                function (){
                                                    switch (opt.type) {
                                                        case 1:
                                                            return '财政部项目';
                                                        case 2:
                                                            return '发改委项目';
                                                        case 3:
                                                            return '地方项目';
                                                        default:
                                                    }
                                                }()
                                            }
                                            </span>|&nbsp;&nbsp;&nbsp;&nbsp;<span>所属行业：{opt.industry_name}</span></div>
                                            <div><span>发起时间：{opt.starttime}</span>|&nbsp;&nbsp;&nbsp;&nbsp;<span>总投资：{opt.budget}亿元</span></div>
                                        </div>
                                    </div>
                                    <div className={css.status}>{opt.updatetime}</div>
                                    <div className={css.time}>
                                        {
                                            function(){
                                                switch (opt.status) {
                                                    case 1:
                                                        return '待审核';
                                                    case 2:
                                                        return '已发布';
                                                    case 3:
                                                        return '审核未通过';
                                                    case 4:
                                                        return '待提交';
                                                    case 5:
                                                        return '暂停';
                                                    default:
                                                }
                                            }()
                                        }
                                    </div>
                                    <div className={css.operation} style={{width:'160px'}}>
                                        <div className={css.btn} onClick={()=>goDetail(opt)}>查看</div>
                                        <div className={css.btn} onClick={()=>push({pathname:'/addproject',state:{id:opt.id,type:'edit'}})}>修改</div>
                                        {/*{opt.status === 2 && <div className={css.btn} onClick={ v=>updateStatus(opt,5,index) }>暂停</div> }*/}
                                        {/*{opt.status === 5 && <div className={css.btn} onClick={ v=>updateStatus(opt,2,index) }>恢复</div>}*/}

                                    </div>
                                </div>
                            </div>
                        )}
                        {
                            !isLoading &&  !dataSource.list.length && <div class={'emptyBox'}>
                                <img src={IconEmpty} alt=""/>
                                <div >暂无数据</div>
                            </div>
                        }
                    <YcPagination
                        total={params.total || 0}
                        pageSize={8}
                        current={params.page}
                        onChange={(e) => {
                            const _params = params;
                            _params.page = e;
                            setParams({..._params})
                        }}
                        style={{margin: '30px 0'}}/>
                    </div>
                </Card>
            </div>
        </div>
    )
}