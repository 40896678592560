import React from 'react';
import css from './titleBox.module.less';


export const TitleBox = (props)=>{

    const {title } = props;
    return (
        <div className={ css.title }>
            {title}
        </div>
    )
}