import React, {Fragment} from 'react';
import css from './card-box.module.less';

export const CardBox = (props)=>{
    const { children,title,ext,more,style } = props;
    return (
        <div className={ css.cardbox } style={style}>
            <div className={ css.title } >
                {title}
                <small className={ css.ext }>{ ext }</small>
                <i className={ css.blank }></i>
                {
                    !!more &&
                    <a className={ css.moreBtn } onClick={ more }>更多></a>
                }
            </div>
            <Fragment>
                {children}
            </Fragment>
        </div>
    );
}