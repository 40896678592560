import React from 'react';
import css from './index.module.less';
import {TitleBox} from "../components/titleBox";
import iconAboutMap from  'aliasimgurl/icon_about_map.png';
/** 网站地图 */
export const Map = (props) => {
    return (
        <div className={css.body}>
            <TitleBox title={'网站地图'} />
            <div className={css.content}>
                <div className={css.row}>
                    <a href={'/obj/new'}>最新项目</a>
                    <a href={'/index'}>首页</a>
                    <a href={'/obj/exchequer'}>财政部项目</a>
                </div>
                <div className={css.row}>
                    <a href={'/info'}>项目资讯</a>
                    <a href={'/obj/NDRC'}>发改委项目</a>
                </div>
                <div className={css.row}>
                    <a href={'/data/trade'}>数据中心</a>
                    <a href={'/obj/local'}>地方项目库</a>
                </div>
                <img src={iconAboutMap} alt="" className={css.bg}/>
            </div>
        </div>
    )
}