import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import * as R from 'ramda';
import demoimg from 'aliasimgurl/img_notices_demo.png';
import {Typography} from 'antd';
import css from './info-project-list.module.less';

export const InfoProjectList =(props)=>{

    const {items=[],goDetail} = props;
    const [after,setAfter] = useState([]);
    const [before,setBefore] = useState([]);
    useEffect(()=>{
        if(items.length){
            let [_before,_after] = R.splitAt(2,items);
            setAfter([..._after]);
            setBefore([..._before]);
        }

    },[items]);

    const onError = ()=>{
        let _r = before.map(opt=>({...opt,images:demoimg}));
        setBefore(_r);
    };

    return (
        <div className={ css.list }>
            <div className={ css.before }>
                {
                    before.map((o,i)=>
                        <div className={ css.item } key={ i } onClick={()=>goDetail(o)}>
                            <div className={ css.imgWrap } >

                                    <img className={ css.img } onError={ (e)=>onError(e) } src={o.images } alt=""/>
                                <div className={ css.imgInnerTitle }>
                                    { o.title }
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
            <div className={ css.after }>
                {
                    after.map((opt,index)=>
                        <div className={ classNames(css.item,index>11?css.noLine:'') } key={ index } >
                            <div className={css.descwrap}>
                                <div className={ css.titleWrap } onClick={()=>goDetail(opt)}>
                                    <div className={css.title}>{ opt.title }</div>
                                    <div className={ css.time }>{ opt.createtime }</div>
                                </div>
                                <div className={ css.desc } >
                                    <Typography.Paragraph ellipsis={ {rows:2,suffix:<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>, onEllipsis: ellipsis => {
                                            console.log('Ellipsis changed:', ellipsis);
                                        },} } style={ {marginBottom:0} } >
                                        {opt.remark}
                                    </Typography.Paragraph>
                                </div>
                            </div>

                        </div>
                    )
                }
            </div>

        </div>
    );
}