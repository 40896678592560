import React from 'react';
import classNames from 'classnames';
import css from './new-project-list.module.less';
import {Statistic} from "antd";

const { Countdown } = Statistic;

export const NewProjectList =(props)=>{

    const {items=[],goDetail} = props;
    return (
        <div className={css.list} style={{minHeight: '300px'}}>
            <div className={classNames(css.item, css.tableTitle)}>
                <div className={css.title}>项目名称</div>
                {/*<div className={css.regin}>项目类型</div>*/}
                <div className={css.regin}>所属行业</div>
                <div className={css.area}>所属地区</div>
                <div className={css.type}>投资预算</div>
                {/*<div className={ css.biddingType }>所处阶段</div>*/}
                <div className={ css.time }>发起时间</div>
            </div>
            {
                items.map((opt, index) =>
                    <div className={css.item} key={index} onClick={() => goDetail(opt)}>
                        <div className={css.title}>{opt.name}</div>
                        {/*<div className={css.regin}> {*/}
                        {/*    function (){*/}
                        {/*        switch (opt.type) {*/}
                        {/*            case 1:*/}
                        {/*                return '财政部项目';*/}
                        {/*            case 2:*/}
                        {/*                return '发改委项目';*/}
                        {/*            case 3:*/}
                        {/*                return '地方项目';*/}
                        {/*            default:*/}
                        {/*        }*/}
                        {/*    }()*/}
                        {/*}</div>*/}
                        <div className={css.regin}>{opt.industry_name}</div>
                        <div className={css.area}>{opt.pname}</div>
                        <div className={css.type}>{opt.budget}亿</div>
                        {/*<div className={ css.biddingType }>{ opt.stage_name }</div>*/}
                        <div className={ css.time }>{ opt.releasetime }</div>
                    </div>
                )
            }
        </div>
    );

}