import React, {Fragment, useEffect, useState} from 'react';
import './App.css';
import {EmptyBox, Footer, IndexHeader, MainNav, TopHeader, YcLoading} from './components';
import {Redirect, Route, Switch} from "react-router-dom";
import cookie from 'react-cookies';
import {Home} from './pages/home';
import {store} from './helps';
import {AddProject} from "./pages/addProject/add-project";
import {ProView} from "./pages/projestList/view";
import {ProList} from "./pages/projestList";
import {Info} from "./pages/info";
import {InfoView} from "./pages/info/view";
import {Account} from "./pages/account";
import {LoginPage} from './pages/login/index';
import {RegPage} from './pages/login/reg';
import {RecoverPage} from './pages/login/recover';
import {DataTrade} from "./pages/dataTrade";
import {About} from "./pages/about";
import {useUserInfo} from "./hook";

function App() {
    useUserInfo();

    const [isLoading,setLoading] = useState(true);
    useEffect(()=>{
        const token = cookie.load('token') || '';
        /*更新token*/
        if(token){
            store.set('isLogin',true, Date.now() + 1000 * 60 * 60 * 24 * 12);// 设置过期时间
            setLoading(false);
        }else{
            setLoading(false);
        };
    },[]);

    return (
        <div className="App">
            {
                isLoading ? <YcLoading />:

                    <Switch>
                        <Redirect exact from="/login" to="/login/login" />
                        <Redirect exact from="/reg" to="/login/reg" />
                        <Redirect exact from="/recover" to="/login/recover" />

                        <Route exact path="/login/:currentModal" component={LoginPage}/>
                        {/*<Route exact path="/login" component={LoginPage}/>*/}
                        {/*<Route exact path="/reg" component={RegPage}/>*/}
                        {/*<Route exact path="/recover" component={ RecoverPage }/>*/}
                        <Route exact path={['/account','/account/:c1','/account/:c1/:c2','/account/:c1/:c2/:c3']} component={ Account }/>
                        <Fragment>
                            <TopHeader key={new Date}/>
                            <IndexHeader />
                            <MainNav />
                            <Switch>
                                <Redirect exact from="/" to="/index" />
                                <Route exact path="/index" component={Home}/>
                                <Route exact path="/addproject" component={AddProject}/>
                                <Route exact path="/obj/new" component={ProList}/>
                                <Route exact path="/obj/exchequer" component={ProList}/>
                                <Route exact path="/obj/NDRC" component={ProList}/>
                                <Route exact path="/obj/local" component={ProList}/>
                                <Route exact path="/obj/view/:id" component={ProView}/>
                                <Route exact path="/info" component={Info}/>
                                <Route exact path="/info/view/:id" component={InfoView}/>
                                <Route exact path="/data/trade" component={DataTrade}/>
                                <Route exact path={['/about','/about/:c1']} component={ About }/>

                                <Route key="error" render={ (props) => <EmptyBox /> } />
                            </Switch>
                            <Footer />

                        </Fragment>
                    </Switch>
            }
        </div>
    );
}

export default App;