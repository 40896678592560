import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom'
import {Breadcrumb, HotCityList, HotInfoList, YcPagination} from '../../components';
import {useDataApi} from '../../hook/dataApi';
import css from './index.module.less';
import banner01 from 'aliasimgurl/obj_banner_01.png';
import {List} from "./components";


export const Info = (props) => {
    let { push } = useHistory();
    const [searchValue, setSearch] = useState({});
    useEffect(()=>{
        let _s= props.location.state;
        if(_s && Object.keys(_s).length){
            setSearch({...searchValue,..._s});
        }
    },[props.location.state]);

    function updateValue(value) {
        let _v = { ...searchValue, ...value };
        setSearch(_v);
    }

    const [ { isLoading,...listData },updateParams ]  =useDataApi({});

    useEffect(() => {
        updateParams({url:'/api/newsList',method:'post',data:{c_id2:384,type_id:0,page:1,pagesize:10, ...searchValue}});
    }, [searchValue]);

    function toView(opt) {
        const {id='ZB2020052354545'} = opt;
        push({pathname:'/info/view/'+id})
    }
    function pageChange (v){
        updateValue({page:v})
    }
    return (
        <div className="container">
            {/*<YcImage imgUrl={ headerImg } onClick={goDetail}  style={{'cursor': 'pointer'}}/>*/}
            <div className={css.bodyWarp}>

                <div className={css.leftWarp}>
                    <Breadcrumb list={ {name:'项目资讯'} } />
                    <div className={ css.listWrap }>
                        <List itemsLength={ 16 } data={ listData.data.data || [] } count={listData.data.count} toView={ toView } />
                        <YcPagination
                            total={ listData.data.count || 0 }
                            current={ searchValue.page}
                            onChange={pageChange}
                            style={ {margin:'30px 0'} } />
                    </div>
                </div>
                <div className={css.rightWarp}>
                    <div className={css.bannerWarp} onClick={()=>push({pathname:'/addproject',state:{}})}>
                        <img src={banner01} alt=""/>
                    </div>
                    <HotCityList style={{marginTop: '30px'}}/>
                    <HotInfoList style={{marginTop: '30px'}}/>
                </div>
            </div>

        </div>
    );
}