import React from 'react';
import {Button, message, Upload} from "antd";
import {UploadOutlined} from '@ant-design/icons';
import {UploadConfig} from "../common";
import css from "./upload.module.less";
import {resourcesUrl} from "../common/static_number";
import moment from "moment";

/**
 *  文件上传组件
 *  @param {String} accept {accept:限制文件类型 }
 *  @param {Number} unloadUnm 限制上传文件数量
 *  @param {Boolean} disabled 是否禁止上传
 *  @param {Function} handleFileChange 文件上传成功回调
 */
export class UploadFile extends React.Component {
    constructor(props) {
        super(props);
        const {
            uploadNum=1,
            accept='',
            defaultFileList= props.value? props.value : [],
            action='https://upload-z0.qiniup.com',
            disabled=false,
            size=50,
        } = props;
        const fileList = defaultFileList.map(( item ) => {
            item.url = resourcesUrl + item.file;
            return {...item}}
        );
        this.state = {
            uptoken:'',
            filename:'',
            fileList,
            uploadNum,
            accept,
            fileBase:resourcesUrl,
            action,
            disabled,
            size
        };
    }

    /**
     *
     * @param file
     * @returns {Promise<unknown>}
     */
    beforeUpload = (file)=>{
        const { uploadNum, accept,size } = this.state;
        return new Promise((resolve, reject)=>{
            console.log("file.size",file.size);
            if(file.size > 1024*1024*size){
                message.error('上传文件为' + (file.size/1024/1024).toFixed(2)+'M ，请控制在'+size+'M以内！');
                reject();
            } else if( accept && !accept.toLowerCase().includes(file.name.slice(file.name.lastIndexOf('.')).toLowerCase())  ){
                message.error('文件格式不正确');
                reject();
            } else if(this.state.fileList.length >= uploadNum){
                message.error('最多上传'+uploadNum+'份文件呦~');
                reject();
            }
            UploadConfig.fetchUploadToken().then(({data:{data:{uptoken:token}}})=>{
                this.setState({
                    uptoken: token,
                    filename: file.name,
                });
                resolve();
            });

        })

    };
    render(){
        const { disabled=false,accept,fileBase,uptoken,size,filename,fileList,uploadNum} = this.state;
        console.log("fileList",fileList);
        const upLoad = {
            action: 'https://upload-z0.qiniup.com',
            data: {
                token: uptoken,
                key: moment(new Date()).format('YYYY-MM-DD')+'/'+parseInt(9999*Math.random())+'/' +filename
            },
            fileList: fileList,
            multiple: uploadNum>1,
            onChange:({ file,fileList }) =>{

                let _fileList = [...fileList];
                _fileList = _fileList.map(file => {
                    if (file.response) {
                        const { key } = file.response;
                        file.url =  fileBase + key;
                        file.key = key;
                    }
                    return file;
                });
                this.setState({ fileList:_fileList });
                if(file.status === 'done' || file.status === "removed"){
                    const list = _fileList.map((item)=>{
                        const { key='' } = item.response ||{};
                        return {uid:item.uid, name: item.name,file:item.file || key}
                    });
                    this.props.handleFileChange &&  this.props.handleFileChange([...list]);
                    this.props.onChange && this.props.onChange([...list]);
                }
                if(file.status === 'error'){
                    message.error("文件上传失败,请重新上传！");
                }
            },
            onRemove:()=>{
              console.log("删除事件")
            },
            beforeUpload: this.beforeUpload,
        };

        return (
            <Upload {...upLoad } accept={accept} disabled={disabled} className={css.upload}>
                <Button disabled={disabled || fileList.length >= uploadNum} style={{display:disabled || (fileList.length >= uploadNum)?'none':'block'}}>
                    <UploadOutlined /> 上传附件
                </Button>
                {accept && <span style={{display:disabled || (fileList.length >= uploadNum)?'none':'block'}} onClick={e =>{ e.preventDefault();e.stopPropagation()}} className={css.upload_text}>(单文件最大{size}M,最多可上传{uploadNum}个文件,支持{accept? accept+'等':'所有'}类型文件)</span>}
            </Upload>
        );
    }

}