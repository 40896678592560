import React from 'react';
import css from './index.module.less';
import {TitleBox} from "../components/titleBox";
import aboutFlow from "aliasimgurl/about_flow.png";
import aboutFlow01 from "aliasimgurl/icon_flow_01.png";
import aboutFlow02 from "aliasimgurl/icon_flow_02.png";
import aboutFlow03 from "aliasimgurl/icon_flow_03.png";
import aboutFlow04 from "aliasimgurl/icon_flow_04.png";

/** 服务介绍 */
export const State = (props) => {
    return (
        <div className={css.body}>
            <TitleBox title={'服务介绍'} />
            <div className={css.content}>
                <p>
                    百大项目库平台（http://project.china-forest.com），是集项目资讯、项目合作、投资交流、数据分析为一体的专业性项目综合服务平台，主要面向全国所涉及的政府、企业、金融机构、专家学者及相关从业人员，为项目筹备、招投标、规划设计、投资、采购、建设、运营等全产业链提供全面、专业的资讯信息、业务合作服务。
                </p>
                <p>
                    作为独立专业的全国性项目交流服务平台，百大项目库平台以引领新时代下项目库研究与创新、提升各方合作机会与效率、促进全国百大项目推广与应用为宗旨，通过全国各省市区县项目自主入库和规划团队主动规划相结合，搭建政府与社会资本之间的桥梁，整合各方优势资源，努力打造成为全国专业、领先的“互联网+”模式下的百大项目库服务平台。
                </p>
            </div>
            <div className={css.footerWarp}>
                <div className={css.title}>
                    项目入库申请流程
                </div>
                <div className={css.footer}>
                    <div className={css.item}>
                        <img src={aboutFlow01} alt=""/>
                        <span>项目入库申请</span>
                    </div>
                    <img src={aboutFlow} alt="" className={css.flow}/>
                    <div className={css.item}>
                        <img src={aboutFlow02} alt=""/>
                        <span>编辑项目信息</span>
                    </div>
                    <img src={aboutFlow} alt="" className={css.flow}/>
                    <div className={css.item}>
                        <img src={aboutFlow03} alt=""/>
                        <span>提交平台审核</span>
                    </div>
                    <img src={aboutFlow} alt="" className={css.flow}/>
                    <div className={css.item}>
                        <img src={aboutFlow04} alt=""/>
                        <span>入库成功</span>
                    </div>
                </div>
            </div>
        </div>
    )
}