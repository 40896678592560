import {useDataApi} from "./dataApi";
import {useEffectOnce} from 'react-use';

export const useMessageNumData = ()=>{

    const [{data:messageNum,setData },updateParams] = useDataApi();
    useEffectOnce(()=>{
        getData();
    });

    const getData =()=>{
        // updateParams({url:'/api/user/msgCount',method:'post'});
    };

    return [messageNum,getData,setData];
}