import React, {useEffect, useState} from "react";
import css from "./depositDetail.module.less";
import {Button, Descriptions, Form, message, Modal} from 'antd';
import {useDataApi} from "../../../hook";
import {SubNav, UserInfo} from "../components";
import {PublishModal} from "../../../common";
import {useHistory} from "react-router-dom";
import {UploadImage} from "../../../components";
import {MODE_OFF} from "../../../common/static_number";


export const DepositDetail = (props) => {
    const {project_sn} = props.location.state;
    const {push} = useHistory();
    const [params, setParams] = useState({});
    const [previewImage] = useState(''); //默认的图片列表

    const [defaultImagesList, setDefaultImagesList] = useState([]); //默认的图片列表
    const [previewVisible, setPreviewVisible] = useState(false);

    const [{data, isLoading}] = useDataApi({
        url: '/api/prosign',
        method: 'post',
        data: {project_sn}
    });

    useEffect(()=>{
        if(!isLoading){
            let _data = data;
            if (_data.certificate && _data.certificate.length) {
                console.log(_data.certificate);
                setDefaultImagesList(_data.certificate)
            }
        }
    },[isLoading]);

    const handleCancel = () => setPreviewVisible(false);

    const statusText = ()=>{
        const {bail_status} = data;
        // 0 未缴纳 1 待审核 2 已缴纳 3 审核未通过 4 已退回 5 已冻结
        switch (bail_status) {
            case 0:
                return <span style={{color:'#139332'}}>未缴纳</span>;
            case 1:
                return <span style={{color:'#FF4200'}}>待审核</span>;
            case 2:
                return <span style={{color:'#139332'}}>已缴纳</span>;
            case 3:
                return <span style={{color:'#FF4200'}}>审核未通过</span>;
            case 4:
                return <span style={{color:'#139332'}}>已退回</span>;
            case 5:
                return <span style={{color:'#999'}}>已冻结</span>;
            default:
                return <span style={{color:'#999'}}>已结束</span>
        }
    };
    /** 图片上传成功事件 */
    const handleImageChange = (v,name) => {
        const _params = params;
        _params.certificate = v;
        console.log(_params);
        setParams({..._params});
    };
    const submit = ()=>{
        const {certificate} = params;
        if (!certificate.length) {
            message.error('请上传保证金凭证');
            return
        }
        PublishModal.payDeposit({prosign_id:data.id,certificate,bond:data.bond,is_change:1}).then(({data:{data,code,msg}})=>{
                if (code === 200) {
                    message.success('提交成功！',3, window.location.reload());
                } else {
                    message.error(msg);
                }
            }
        );
    };
    return (
        <div className={css.body}>
            <div className={css.sideBar}>
                <UserInfo/>
                <SubNav/>
            </div>
            {!isLoading && <div className={css.mainBody}>
               <div className={css.audit}>
                    <div className={css.boxTitle}>
                        审批管理 > 保证金 > {statusText()}
                    </div>

                       <Descriptions bordered column={2} style={{marginBottom: '20px'}}>
                           <Descriptions.Item label="公告标题" span={2}>{data.title}</Descriptions.Item>
                           <Descriptions.Item label="项目名称">{data.project_name}</Descriptions.Item>
                           <Descriptions.Item label="项目编号">NO:{data.project_sn}</Descriptions.Item>
                           <Descriptions.Item label="行业分类">{data.industry_id} - {data.industry_type}</Descriptions.Item>
                           <Descriptions.Item label="招标类型">{data.tendering_type}</Descriptions.Item>
                           <Descriptions.Item label="招标方式">{data.tendering_mode === MODE_OFF ?'仅线上发布招标公告':'在线电子招投标' }</Descriptions.Item >
                           <Descriptions.Item label="所属地区">{data.address}</Descriptions.Item>
                           <Descriptions.Item label="项目金额">{data.starting_price} (万元)</Descriptions.Item>
                           <Descriptions.Item label="投标保证金">{data.bond} (万元)</Descriptions.Item>
                           <Descriptions.Item label="联系人">{data.user_name}</Descriptions.Item>
                           <Descriptions.Item label="联系电话">{data.user_phone && data.user_phone.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2')}</Descriptions.Item>
                       </Descriptions>
                   <Descriptions column={1} >
                       <Descriptions.Item label="上传时间">{data.certificatetime}</Descriptions.Item>
                       <Descriptions.Item label="缴纳时间">{data.bondtime}</Descriptions.Item>
                       <Descriptions.Item label='汇款凭证'>
                           {
                               defaultImagesList.length> 0 && <UploadImage disabled={true} uploadNum={3} value={defaultImagesList}/>
                           }
                           <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
                               <img alt="example" style={{width: '100%'}} src={previewImage}/>
                           </Modal>
                       </Descriptions.Item>
                       {
                           !data.bail_remark ||
                               <Descriptions.Item label="审批意见">
                                   <div className={css.remark}>
                                       {data.bail_remark}
                                   </div>
                               </Descriptions.Item>
                       }
                   </Descriptions>
                    <div className={css.btns}>
                        {
                            (data.bail_status === 3 || data.bail_status === 0) ?
                        <Button size={"large"} type={"primary"} onClick={()=> push({pathname:'/market/tender',state:{project_sn,step:2}})}>
                            {data.bail_status === 3 ? '重新上传': '去上传'}
                            </Button> :
                            <Button  size={"large"}  onClick={()=>window.history.back()}>
                           返回
                            </Button>}
                    </div>
                </div>
            </div> }
        </div>
    );
}
