import React from 'react';
import classNames from 'classnames';
import css from './index.module.less';
import {IndustryRanking, AreaRanking, StageCount} from "./components";
import icon_data_01 from 'aliasimgurl/icon_data_01.png';
import icon_data_02 from 'aliasimgurl/icon_data_02.png';
import icon_data_03 from 'aliasimgurl/icon_data_03.png';
import icon_data_04 from 'aliasimgurl/icon_data_04.png';
import icon_data_05 from 'aliasimgurl/icon_data_05.png';
import icon_data_06 from 'aliasimgurl/icon_data_06.png';
import data_banner_name from 'aliasimgurl/data_banner_name.png';
import {useDataApi} from "../../hook";


export const DataTrade = (props) => {
    const [{data,isLoading }] = useDataApi({ url: '/pro/statistics', method: 'post', initialData: {}});

    const dealNumber = (num) => {
        if (num) {
            num = num + "";
            //整数部分
            //将整数部分拆解为单个数字的数组倒序，然后拼装为新的数组，每3位数字进行一次匹配
            let intStrList = num.split("").reverse().join('').match(/(\d{1,3})/g);
            //将匹配后的数组用，拼接，再拆解为单个数字的数组，反转为原先数组形式，拼接为完整数字
            let intList = intStrList.join(',').split('').reverse();
            const html = intList.map((item)=>{
                if(item === ','){
                    return ','
                }else{
                    return <span>{item}</span>
                }
            });
            return html;
        }
        return num;
    }

    return (
        <div className={css.dataBox}>
            <div className={css.banner}>
                <img className={css.bannerName} src={data_banner_name} />
                <div className={css.bannerNumber}>统计时间：截止为{data.time}</div>
            </div>
            {!isLoading && <div className='container'>
                <div className={classNames(css.dataWarp, css.countBox)}>
                    <div className={css.item}>
                        <img className={css.icon} src={icon_data_01}/>
                        <img className={css.icon_hover} src={icon_data_02}/>
                        <div className={css.name}>全国入库项目总数</div>
                        <div className={css.number}>{dealNumber(data.projectnum)}</div>
                    </div>
                    <div className={css.item}>
                        <img className={css.icon} src={icon_data_03}/>
                        <img className={css.icon_hover} src={icon_data_04}/>
                        <div className={css.name}>覆盖省份</div>
                        <div className={css.number}>{dealNumber(data.provicenum)}</div>
                    </div>
                    <div className={css.item}>
                        <img className={css.icon} src={icon_data_05}/>
                        <img className={css.icon_hover} src={icon_data_06}/>
                        <div className={css.name}>项目总金额/亿</div>
                        <div className={css.number}>{dealNumber(data.projectmoney)}</div>
                    </div>
                </div>
                <div className={css.dataWarp}>
                    <IndustryRanking data={data.industry}/>
                </div>
                <div className={css.dataWarp}>
                    <AreaRanking data={data.province}/>
                </div>
                <div className={css.dataWarp}>
                    <StageCount data={data.stage}/>
                </div>
            </div>
            }

            {/*<YcImage imgUrl={ headerImg } onClick={goDetail}  style={{'cursor': 'pointer'}}/>*/}


        </div>
    );
}