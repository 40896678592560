import React from 'react';
import css from './index.module.less';
import {TitleBox} from "../components/titleBox";
import {Input,Button,Form,Select,message} from 'antd';
import { UploadImage} from "../../../components";
import {ServicesModel} from "../../../common";

const {Option} = Select;
const {TextArea } = Input;
/** 意见反馈 */
export const Suggestion = (props) => {
    const [ideaForm] = Form.useForm();
    /** 意见反馈 */
    const saveIdea = (v)=>{

        ServicesModel.saveIdea(v).subscribe({
            next:res=>{
                message.success('提交成功',1,()=>{
                    ideaForm.resetFields();
                })
            }
        })
    };
    return (
        <div className={css.body}>
            <TitleBox title={'意见反馈'} />
            <div className={css.content}>
                <Form form={ideaForm} onFinish={saveIdea} labelCol={{span:9}} wrapperCol={{span:8}}>
                    <Form.Item name={'type'} label={'反馈类型'} rules={[{ required: true, message: '请选择反馈类型' }]} >
                        <Select placeholder="请选择反馈类型">
                            <Option value={'9'}>优化建议</Option>
                            <Option value={'10'}>页面报错</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="邮箱"
                        name="email"
                        rules={[{
                            type: 'email',
                            message: '邮箱格式不正确',
                        },{required: true, message: '请输入邮箱' }]}
                    >
                        <Input autoComplete="off" placeholder="请输入联系邮箱"/>
                    </Form.Item>
                    <Form.Item name={'content'} label={'详细描述'}  rules={[{ required: true, message: '请输入详细描述' }]}>
                        <TextArea autoSize style={{minHeight: '80px'}} placeholder={'请输入详细描述'}/>
                    </Form.Item>
                    <Form.Item label={'上传图片'} name={'file'}>
                        <UploadImage uploadNum={5} accept={'.jpg, .jpeg, .png, .gif'}/>
                    </Form.Item>
                    <Form.Item wrapperCol={{offset:9}}>
                        <Button type={"primary"} htmlType={'submit'} style={{width: '120px',margin: '36px auto'}}>提交</Button>
                    </Form.Item>
                </Form>
            </div>

        </div>
    )
}