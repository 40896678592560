import React, {Fragment} from 'react';
import css from './view-box.module.less';

export const ViewBox = (props)=>{
    const { name,style,children } = props;
    return (
        <div className={ css.cardbox } style={style}>
            <div className={ css.title } >
                {name}
            </div>
            <Fragment>
                {children}
            </Fragment>
        </div>
    );
}