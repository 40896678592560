import React, {useState} from 'react';
import css from './statusWarp.module.less';

export const StatusWarp  = (props)=>{
    const [status,setStatus] = useState('0');
    const handleChange = (value) =>{
        setStatus(value);
        props.onChange && props.onChange(value)
    };

    return (
        <div className={ css.statusWarp }>
            <div className={css.typeBox}>
                <div className={status === '0' ? css.active : ''}
                     onClick={() => handleChange('0')}>全部
                </div>
                <div className={status === '1' ? css.active : ''}
                     onClick={() => handleChange('1')}>等待审核
                </div>
                <div className={status === '2' ? css.active : ''}
                     onClick={() => handleChange('2')}>已发布
                </div>
                <div className={status === '3' ? css.active : ''}
                     onClick={() => handleChange('3')}>审核未通过
                </div>
                <div className={status === '4' ? css.active : ''}
                     onClick={() => handleChange('4')}>待提交
                </div>
                {/*<div className={status === '5' ? css.active : ''}*/}
                {/*     onClick={() => handleChange('5')}>暂停*/}
                {/*</div>*/}
            </div>
        </div>

    )
}


export const TypeWarp  = (props)=>{
    const [status,setStatus] = useState('0');
    const handleChange = (value) =>{
        setStatus(value);
        props.onChange && props.onChange(value)
    };

    return (
        <div className={ css.statusWarp }>
            <div className={css.typeBox}>
                <div className={status === '0' ? css.active : ''}
                     onClick={() => handleChange('0')}>全部
                </div>
                <div className={status === '1' ? css.active : ''}
                     onClick={() => handleChange('1')}>财政部项目
                </div>
                <div className={status === '2' ? css.active : ''}
                     onClick={() => handleChange('2')}>发改委项目
                </div>
                <div className={status === '3' ? css.active : ''}
                     onClick={() => handleChange('3')}>地方项目
                </div>
            </div>
        </div>

    )
}