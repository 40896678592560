import React, {useEffect} from 'react';
import {useEffectOnce} from 'react-use';
import {Breadcrumb, HotCityList, HotInfoList,} from '../../components';
import {message} from 'antd';
import {useDataApi} from '../../hook';
import css from './index.module.less';
import {ProModal} from "../../common";
import {tap} from "rxjs/operators";
import banner01 from 'aliasimgurl/obj_banner_01.png';
import {createFromIconfontCN} from '@ant-design/icons';
import {useHistory} from 'react-router-dom'

const IconFont = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1696985_l6tmts73qde.js',
});
export const InfoView = (props) => {
    const {id=''} = props.match.params ? props.match.params : {};
    const [{data,isLoading,setData},updateParams] = useDataApi();
    const {push} = useHistory();
    useEffectOnce(() => {
        function scrollToTop(argument) {
            const c = document.documentElement.scrollTop || document.body.scrollTop;
            if (c > 0) {
                window.requestAnimationFrame(scrollToTop);
                window.scrollTo(0, c - c / 8);
            };
        };
        scrollToTop();
    });
    useEffect(()=>{
        updateParams({url: '/api/getNewsDetail', method: 'post', data: {id}})
    },[id]);

    /** 收藏 */
    const handleCollect = ()=>{
        ProModal.collect({id,type:6}).pipe(
            tap(res=>console.log(res))
        ).subscribe({
            next:res=>{
                message.success('收藏成功',3);
                const _data = data;
                _data.is_collect = true;
                setData({..._data})
            },
            error:err=>{},
            complete:()=>{}
        });
    };
    /** 取消收藏 */
    const handleCancelCollect = ()=>{
        ProModal.cancelCollect({id,type:6}).pipe(
            tap(res=>console.log(res))
        ).subscribe({
            next:res=>{
                message.success('取消成功',3);
                const _data = data;
                _data.is_collect = false;
                setData({..._data})
            },
            error:err=>{},
            complete:()=>{}
        });
    };

    return (
        <div className="container">
            {/*<YcImage imgUrl={ headerImg } />*/}
            {!isLoading &&
                <div className={css.bodyWarp}>
                    <div className={css.leftWarp}>
                        <Breadcrumb list={ [{name:'项目资讯',path:'/info'},{name:data.title||""}] } />
                        <div className={ css.viewWrap }>
                            <div className={ css.titleInfo }>
                                <div className={ css.viewTitle }>{data.title}</div>
                                <p>
                                    <span><IconFont type={'icon-time'}/><span className={css.text}>{data.createtime}</span></span>
                                    <span><IconFont type={'icon-chakan'}/><span className={css.text}>{data.read_num}</span></span>
                                    {
                                        data.is_collect?  <span className={css.check}  onClick={handleCancelCollect} style={{cursor: "pointer"}}>
                                            <IconFont type={'icon-collection-b'}/>
                                            <span className={css.text}>已收藏</span>
                                        </span>:<span onClick={handleCollect} style={{cursor: "pointer"}}>
                                           <IconFont  type={'icon-shoucang1'}/><span className={css.text} >收藏</span>
                                        </span>
                                    }
                                </p>
                            </div>
                            <div className={css.desInfo} dangerouslySetInnerHTML={{__html: data.content}}/>
                        </div>
                    </div>
                    <div className={css.rightWarp}>
                        <div className={css.bannerWarp} onClick={()=>push({pathname:'/addproject',state:{}})}>
                            <img src={banner01} alt=""/>
                        </div>
                        <HotCityList style={{marginTop: '20px'}}/>
                        <HotInfoList style={{marginTop: '20px'}}/>
                    </div>
                </div>
            }
        </div>
    )
}