import React from 'react';

import echarts from 'echarts'

/** 阶段统计 */
export class StageCount extends React.Component{
    constructor(props) {
        super(props);
        const {data=[]} = props;
        this.state = {
            data
        }
    }
    componentDidMount(){
        this.loadMap();
    }

     loadMap =()=> {
        const option = {
            title: {
                text: '项目阶段统计',
                subtext: '注释：项目在各阶段的占比情况',
                left: 'left',
                subtextStyle:{
                    fontSize: 12
                }
            },
             tooltip: {
                 // trigger: 'item',
                 formatter: '{b} : {c} ({d}%)'
             },
            toolbox: {
                show: true,
                feature: {
                    saveAsImage: {
                        pixelRatio: 4
                    }
                }
            },
             legend: {
                 orient: 'vertical',
                 right: 140,
                 top: 155,
                 itemGap:20,
                 itemWidth: 35,
                 itemHeight: 21,
                 textStyle: { //图例文字的样式
                     color: '#666',
                     padding: [0,10]
                 },
             },
            textStyle:{
                fontSize:14,
            },
             series: [
                 {
                     // name: '阶段统计',
                     type: 'pie',
                     radius: '65%',
                     left: '-100px',
                     top: '20px',
                     bottom: '0',

                     // layoutSize: 100,

                     data: this.state.data,
                     axisLabel: {
                         textStyle: {
                             color: '#666',
                             fontSize : 14      //更改坐标轴文字大小
                         }
                     },
                     emphasis: {
                         itemStyle: {
                             shadowBlur: 10,
                             shadowOffsetX: 0,
                             shadowColor: 'rgba(0, 0, 0, 0.5)'
                         }
                     },

                 }
             ]
         };
        var myChart = echarts.init(document.getElementById('stageCount'));

        myChart.setOption(option);
    }

    render(){
        return (
            <div >
                <div id={'stageCount'}  style={{ width: '100%', height: 446 }} />
            </div>
        );
    }

}