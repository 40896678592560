import React, {useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom';
import css from './SubNav.module.less';
import {AppstoreOutlined, ClusterOutlined, SettingOutlined, SoundOutlined, StarOutlined} from '@ant-design/icons';
import {Badge} from "antd";
import {useMessageNumData} from "../../../hook/messageNumData";
import {useUserInfo} from "../../../hook";


export const SubNav = (props)=>{

    const subNav = [{
        label:'账户设置',
        icon: <SettingOutlined />,

        path:'/account/setting'
    },{
        label:'消息通知',
        icon: <SoundOutlined />,
        path:'/account/work'
    },{
        label:'项目管理',
        path:'/account/project',
        icon: <AppstoreOutlined />,
    },{
        label:'收藏夹',
        path:'/account/collect',
        icon: <StarOutlined />,

    }];

    const [currentUrl,setCurrentUrl] = useState('');
    const [noticeNum]=  useMessageNumData();
    useEffect(() => {
        setCurrentUrl(window.location.pathname)
    }, [window.location]);
    return (
        <div className={ css.navList }>
            {
                subNav.map((opt,index)=>
                    <div key={ index }>
                        {
                             <NavLink to={ opt.path } className={ opt.children ?css.nav: css.parentNav } activeClassName={ opt.children ? css.selected: css.selected}>
                                <div>
                                    <span>{opt.icon}  { opt.label }</span>
                                    {opt.label === '消息通知' && currentUrl !== opt.path && <Badge style={{padding:0}} count={noticeNum.msgcount ||0}  overflowCount={99} offset={[0,-2]}/>}
                                    {opt.label === '项目管理' && !currentUrl.includes(opt.path) && <Badge style={{padding:0}} count={noticeNum.opencount+noticeNum.commentcount+noticeNum.approvalcount || 0}  overflowCount={99} offset={[0,-2]}/>}
                                </div>
                            </NavLink>
                        }
                        {
                            opt.children && currentUrl.includes(opt.path)   &&
                            <div>
                                {opt.children.map((o,i)=>
                                    <NavLink key={ i } to={ o.path }
                                        className={ css.childrenNav } activeClassName={ css.selected }>
                                        <div>
                                            <span>{ o.label }</span>
                                            {o.label ==='开标项目' &&  !currentUrl.includes(o.path) && <Badge style={{padding:0}} count={noticeNum.opencount || 0}  overflowCount={99} offset={[0,-2]}/> }
                                            {o.label ==='评标项目' &&  !currentUrl.includes(o.path) && <Badge style={{padding:0}} count={noticeNum.commentcount || 0}  overflowCount={99} offset={[0,-2]}/> }
                                            {o.label ==='审批管理' &&  !currentUrl.includes(o.path) && <Badge style={{padding:0}} count={noticeNum.approvalcount || 0}  overflowCount={99} offset={[0,-2]}/> }

                                        </div>
                                    </NavLink>
                                )}
                            </div>
                        }
                    </div>
                )
            }
        </div>
    )
}