import React from 'react';
import css from './areaRanking.module.less';
import echarts from 'echarts'
import chinaJson from 'echarts/map/json/china.json';


/** 地区排名 */
export class AreaRanking extends React.Component{
    constructor(props) {
        super(props);
        const {data=[]} = props;
        this.state ={
            data
        }
    }

    componentDidMount() {
        echarts.registerMap('china', chinaJson);

        let nameList = [];
        let yData = [];
        let pieces=[];
        const colors = ["#81C9AC","#819FC9","#5987A1","#6259A1","#9659A1","#A93D69","#711B81","#7CC05D","#2A8862","#C44770",];
        let data = [];
        this.state.data.reverse().map((item,index)=>{
            const {name,value} = item;
            nameList.push(name);
            yData.push(value);
            pieces.push({label:name,value:index,color:colors[index]});
            data.push({name,value:[value, index]});
        });
        console.log('nameList',nameList);
        console.log( "this.state.data", this.state.data);
        const option = {
            title:[ {
                text: '热门区域排名',
                subtext: '注释：图中数值为热门区域发布的项目总数',
                left: 'left',
                subtextStyle:{
                    fontSize: 12
                }
            }],
            toolbox: {
                show: true,
                feature: {
                    saveAsImage: {
                        pixelRatio: 1
                    }
                }
            },
            tooltip: {
                formatter: function(params) {
                    const {data:{name='',value=''}={}} = params;
                    if(value){
                        return name + '： ' + value[0];
                    }else{
                        return name
                    }
                }

            },
            checkpointStyle:{
                symbol: 'none'
            },
            textStyle:{
                fontSize:12
            },
            visualMap: {
                id: 'areaId',
                type: 'piecewise',
                calculable: true,
                show: false,
                textStyle:{
                    fontSize: 14,
                },
                dimension: 1,
                pieces: pieces ,

            },
            xAxis: {
                show: false,
            },
            yAxis: [{
                id:'barId',
                type: 'category',
                boundaryGap: true,
                // position: 'left',
                nameLocation:'start',
                axisTick: {
                    show:false,
                },
                axisLine: {
                    show: false,
                },

                axisLabel: {
                    textStyle: {
                        color: '#666',
                        fontSize : 14      //更改坐标轴文字大小
                    }
                },
                data: nameList,
            }],


            grid: [{
                id:'barId',
                right: '8%',
                top: '12%',
                width:180,
                height: '80%',
                containLabel: true,
            }],
            series: [
                {
                    id: 'areaId',
                    type: 'map',
                    map: 'china',
                    layoutCenter: ['40%', '55%'],
                    layoutSize: 600,
                    showLegendSymbol: false,
                    // roam: false,
                    // right: 50,
                    label: {
                        color: '#fff',
                        normal: {
                            show: true
                        },
                        emphasis: {
                            show: true
                        },
                    },
                    data: data,
                    itemStyle:{
                        areaColor: '#D4E3E8',
                    },
                }, {
                    type: 'bar',
                    z: 3,
                    barWidth: 14,
                    visualMap: false,
                    splitLine: {
                        show: false
                    },
                    label: {
                        show: true,
                        normal: {
                            position: 'right',
                            show: true
                        },
                    },
                    itemStyle: {
                        barBorderRadius: 8,
                    },
                    data:  data,
                }
            ],

        };
       var myChart = echarts.init(document.getElementById('ranking'));
        myChart.setOption(option);
    }
    render(){
        return (
            <div className={css.list}>
                <div id={'ranking'}  style={{ width: '100%', height: 540}} />
            </div>
        );
    }


}