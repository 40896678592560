import React from 'react';
import {NavLink} from 'react-router-dom'
import css from './main-nav.module.less';
import {useEffectOnce} from 'react-use';

export const MainNav = (props) => {

    const mainNav = [
        {
            path:`/index`,
            label:'首页',
        },{
            path:`/obj/new`,
            label:'最新项目',
        },{
            path:`/obj/exchequer`,
            label:'财政部项目库',
        },{
            path:`/obj/NDRC`,
            label:'发改委项目库',
        },{
            path:`/obj/local`,
            label:'地方项目库',
        },{
            path:`/info`,
            label:'项目资讯',
        },{
            path:`/data/trade`,
            label:'数据中心',
        },{
            path:`/about/state`,
            label:'服务介绍',
        }
    ];
    return (
        <div className={ css['main-nav'] }>
            <div className="container">
                <div className={ css.navList }>
                    {
                        mainNav.map((opt,index)=>
                            <NavLink key={ index }
                                to={ opt.path } className={ css.nav }
                                activeClassName={ css.selected }>
                                <div>
                                    <span>{ opt.label }</span>
                                </div>
                            </NavLink>
                        )
                    }
                </div>
            </div>
        </div>
    );
}