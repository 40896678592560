import React from 'react';
import {Route, Switch} from "react-router-dom";
import css from './index.module.less';
import {SubNav} from "./components";
import {State} from "./state";
import {Map} from "./map";
import {Home} from "./home";
import {Authority} from "./authority";
import {Copyright} from "./copyright";
import {Suggestion} from "./suggestion";

/** 关于我们 */
export const About = (props) => {

    return (
        <div style={{background: '#F5F5F3'}}>
            <div className="container">
                <div className={css.about}>
                    <SubNav />
                    <Switch>
                        <Route exact from="/about/home" component={Home} />
                        <Route exact path="/about/state" component={State}/>
                        <Route exact path="/about/map" component={Map}/>
                        <Route exact path="/about/authority" component={Authority}/>
                        <Route exact path="/about/copyright" component={Copyright}/>
                        <Route exact path="/about/suggestion" component={Suggestion}/>
                    </Switch>
                </div>
            </div>
        </div>
    );
}