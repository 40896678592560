import React from 'react';
import css from './index.module.less';
import {useDataApi} from "../../../hook";
import {TitleBox} from "../components/titleBox";

/** 关于我们 */
export const Home = (props) => {
    const [{ data }] = useDataApi({ url: '/api/aboutus', method: 'post', data: {type:3 }, initialData: {} });
    console.log("data",data);
    return (
        <div className={css.body}>
            <TitleBox title={'关于我们'} />
            <div className={css.content}>
                <div dangerouslySetInnerHTML={{__html:data.content}}></div>
            </div>

        </div>
    )
}