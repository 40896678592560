import React from 'react';
import {Steps} from 'antd';
import css from './add-project-steps.module.less'
const { Step } = Steps;

export const AddProjectSteps = (props)=>{
    const {current=0} = props;
    return (
        <div style={{display:'flex'}} className={css.steps}>
            <Steps current={current}>
                <Step title="填写基本信息" />
                <Step title="填写效益信息" />
                <Step title="填写投资信息" />
                <Step title="平台审核" />
                <Step title="完成"/>
            </Steps>
        </div>
    )
}