import React from 'react';
import {IndexLogo} from '../../../components';
import {NavLink} from 'react-router-dom'
import {useMainNavData} from '../../../hook';
import classNames from 'classnames';
import css from './AccountHeader.module.less';
import {MASTER_URL} from "../../../common/static_number";

export const AccountHeader = ()=>{
    const mainNav = [
        {
            path:`/index`,
            label:'首页',
        },{
            path:`/obj/new`,
            label:'最新项目',
        },{
            path:`/obj/exchequer`,
            label:'财政部项目库',
        },{
            path:`/obj/NDRC`,
            label:'发改委项目库',
        },{
            path:`/obj/local`,
            label:'地方项目库',
        },{
            path:`/info`,
            label:'项目资讯',
        },{
            path:`/data/trade`,
            label:'数据中心',
        }
    ];
    return (
        <div className={ css.accountHeaderWrap }>
            <div className={ classNames("container",css.accountHeader) }>
                <div className={ css.logo }>
                   <IndexLogo />
                </div>
                <div className={ css.navList }>
                    {
                        mainNav.map((opt,index)=>
                            <NavLink key={ index }
                                to={ opt.path }>
                                <div>
                                    <span>{ opt.label }</span>
                                </div>
                            </NavLink>
                        )
                    }
                </div>
            </div>
        </div>

    )
}