import React from 'react';
import {Input} from 'antd';
import {useHistory} from "react-router-dom";
import css from './index-search.module.less'
const { Search } = Input;

export const IndexSearch = () => {

    const {push} = useHistory();
    const handleSearch = e=>{
        push({pathname:'/obj/new',state:{search_key:e}})
    };
    return (
        <Search size={"large"} placeholder="请输入关键字进行搜索"
            onSearch={handleSearch}
            enterButton="搜索" className={css.searchWrap}/>
    );
};