import React from 'react';
import css from './common-list.module.less';
import {CardBox} from "./card-box";
import common_list_before from'aliasimgurl/common_list_before.png'

export const CommonList =(props)=>{

    const {items=[]} = props;

    return (
            <CardBox title="供应商推荐" style={{background: '#F8F8F8',padding: '0 23px',marginTop: '30px'}}>
                <div className={ css.list }>
                    {
                        items.map((opt,index)=>
                            <div className={ css.item } key={ index }>
                                <div className={ css.title }><img src={common_list_before} alt=""/> { opt.store_name }</div>
                            </div>
                        )
                    }
                </div>
            </CardBox>

    );
}