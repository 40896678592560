import {useEffect, useState} from 'react';
import {message, notification} from 'antd';
import cookie from 'react-cookies'
import axios from 'axios';
import {store} from '../helps';
import {stringify} from "qs";
// export const CancelToken = axios.CancelToken;


const openNotificationWithIcon = (type, title, message ) => {
    notification[type]({
        message: title,
        description: message,
    });
};


export const useDataApi = (initialArgs = {}) => {
    const [data, setData] = useState(initialArgs.initialData || {});
    const [args, updateParams] = useState(initialArgs || {});
    const [isLoading, setIsLoading] = useState(true);
    const [Loading,setLoading] = useState(false);//加载状态
    const [isError, setIsError] = useState(false);
    useEffect(() => {
        let { baseURL, url, method, data, params, /* cancelToken,*/ headers, } = args;
        baseURL = baseURL || '';
        method = method || 'get';
        data = headers? (data || {}) : stringify(data || {}) ;
        params = params || {};
        // cancelToken = cancelToken || CancelToken;
        headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'token':cookie.load('token') || '',
            // 'apptype':'pc',
            ...headers
        };

        const fetchData = async () => {
            setIsError(false);
            setIsLoading(true);
            setLoading(true);
            setData(args.initialData || {});
            try {
                const {data:{data:result,msg,code}} = await axios({ baseURL, url, method, data, params, /*cancelToken,*/ headers });
                if(code === 200 ){
                    setData(result);
                }else{
                    message.error(msg)
                }
                setIsLoading(false);
                setLoading(false);
            } catch (error) {
                openNotificationWithIcon('error','错误信息',error.toString());
                setIsError(true);
            }

        };
        if (url) {
            fetchData();
        };
    }, [args]);
    return [{ data, isLoading,Loading, isError,setData }, updateParams];
};
