import React, {useState} from 'react';
import {LoginLayout} from '../common/login-layout';
import bgImg from 'aliasimgurl/img_bg_login.png';
import {Controller, useForm} from "react-hook-form";
import {Button, Input, message, Modal} from 'antd';
import Icon from '@ant-design/icons';
import {NavLink, useHistory} from 'react-router-dom';
import {ServicesModel} from '../../common';
import {tap} from "rxjs/operators";
import classNames from 'classnames';
import css from './index.module.less';
import ga from 'aliasimgurl/ga.png';

import {RegPage} from "./reg";
import {RecoverPage} from "./recover";

export const  LoginPage = (props)=>{
    const { handleSubmit, control/*,getValues,setValue*/ } = useForm(); // initialise the hook
    const [loginType, setLoginType] = useState(2);
    const [currentModal] = useState(props.match.params.currentModal);
    const onSubmit = data => {
        const {username,password} =data;
        if(!username){
            return message.error({ style: {
                    marginTop: '20vh',
                },content:'登录账号不能为空！'});
        };
        if(!password){
            return message.error({ style: {
                    marginTop: '20vh',
                },content:'登录密码不能为空！'});
        };
        ServicesModel.doLogin({username,password,type:loginType}).pipe(
            tap(res=>console.log(res,'pipe'))
            ).subscribe({
                next:res=>{

                    window.location.href = '/';
                },
                error:err=>{},
                complete:()=>{}
            });
    };

    return (
        <LoginLayout style={ {background:`url(${ bgImg }) center bottom no-repeat`, backgroundSize: 'cover'} } >
            <div className={ css.iPage }>
                <div className={ classNames(css.iForm,currentModal ==='login'? css.show : css.hide) } >
                    <div className={ css.iWrap }>
                        <div className={css.welcome}>
                            Welcome
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)} className={ css.formWrap }>

                            <div className={ css.formItem }>
                                <div className={ classNames(css.tapsWrap)  }>
                                    <div className={ classNames(css.tap,loginType===2?css.active:'') }
                                         onClick={ ()=>setLoginType(2) }>个人登录</div>
                                    <div className={ classNames(css.tap,loginType===1?css.active:'') }
                                         onClick={ ()=>setLoginType(1) }>企业登录</div>
                                </div>
                            </div>

                            <div className={ css.formItem }>
                                <div className={css.formItemLabel}>
                                    登录账号
                                </div>
                                <div className={ css.formItemCon }>
                                    <Controller
                                        as={<Input size="large" prefix={ <Icon type="user" /> } style={{width:'100%'}} />}
                                        name="username"
                                        control={control}
                                        placeholder={loginType === 2 ? "请输入手机号":"请输入用户名/手机号"}
                                        defaultValue="" autoComplete="off"/>
                                </div>
                            </div>
                            <div className={ css.formItem }>
                                <div className={css.formItemLabel}>
                                    输入密码
                                </div>
                                <div className={ css.formItemCon }>
                                    <Controller
                                        as={<Input.Password size="large" prefix={ <Icon type="lock" /> } style={{width:'100%'}} />}
                                        name="password"
                                        control={control}
                                        placeholder="请输入密码"
                                        defaultValue="" />
                                </div>
                            </div>

                            <div className={ css.extBox } >
                                <NavLink to="/recover">忘记密码</NavLink>
                                <NavLink to="/reg">立即注册</NavLink>
                            </div>
                            <div className={ css.formItem }>
                                <div className={ css.btnWrap }>
                                    <div className={ css.btnInner }>
                                        <Button htmlType="submit">登&nbsp;录</Button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                {
                    currentModal ==='recover' ? <RecoverPage location={props.location}  currentModal={currentModal}/>  :''
                }
                {
                    currentModal ==='reg' ? <RegPage location={props.location}  currentModal={currentModal}/> :''
                }

            </div>
            <div className={css.footer}>
                <div>
                    增值电信业务经营许可证:京20204808号&nbsp;&nbsp;&nbsp;&nbsp;京ICP备20019858号-1&nbsp;&nbsp;&nbsp;&nbsp;<a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010102004231"><img src={ga} /><span>京公网安备 11010102004231号</span></ a>
                </div>
                <div>
                    © 2020 版权所有 中国林业产业服务与交易平台
                </div>
            </div>
        </LoginLayout>

    )
}