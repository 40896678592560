/* 设置静态常量 */
/* start 用户权限类常量 start */
/** 静态资源路径 */
// export const resourcesUrl = 'http://api.zhongzhenshengtai.com/';
export const resourcesUrl = 'http://img.resources.com.cn/';
/** 普通用户 */
export const USER = 0;
/** 管理员 */
export const USER_ADMIN = 1;
/** 超级管理员 */
export const USER_ = 2;

/** 供应商 */
// export const SUPPLIER  = 1;
/** 招标方 */
export const INVITER  = 2;
/** 供应商兼招标方 */
export const SUPPLIER_AND_INVITER = 3;
/** 招标代理 */
export const AGENT = 4;


/* end 用户权限类常量 end */
/** 等待 */
export const AWAIT = 0;
/** 通过 */
export const SUCCESS = 1;
/** 拒绝 */
export const FAIL = 2;
/** 结束 */
export const OVER = 3;
/* start 状态常量 start */

/* end 状态常量 end */


/* start 招标类常量 start */
/** 招标 */
export const TYPE_BIDDING = 1;
/** 采购 */
export const TYPE_PROCUREMENT = 2;
/** 公开招标 */
export const TYPE_COMMON = 1;
/** 邀请招标 */
export const TYPE_INVITE = 2;

/** 线上 */
export const MODE_ON = 1;
/** 线下 */
export const MODE_OFF = 2;
/* end 招标类常量 end */

/*start 供应商审批人 1=经办人，2=招标公告审批人，3=供应商审批人，4=招标文件审批人，5=开标审批人，6=评标审批人，7=定标人，8=中标公示审批人，9=中止招标审批人start*/
/**  经办人   */
export const AUDIT_OPERATOR = 1;

/**  招标公告审批   */
export const AUDIT_PUBLISH = 2;

/**  供应商审批   */
export const AUDIT_SUPPLIER = 3;

/**  招标文件审批人   */
export const AUDIT_SEND = 4;

/**  开标审批   */
export const AUDIT_OPEN = 5;

/**  评标审批   */
export const AUDIT_COMMENT = 6;

/**  定标审批   */
export const AUDIT_CONFIRM = 7;

/**  中标公示审批   */
export const AUDIT_CONFIRM_PUBLISH = 8;

/**  中止招标审批人   */
export const AUDIT_STOP = 9;

/** 供应商 */
export const SUPPLIER = 10;
/*end 供应商审批人 end*/

/* 审批状态 */
export const APPROVAL = 0;

/** 发布招标文件 */
export const PUBLISH_FILE = 12;


/** start 问答阶段常量 start*/
//类型:1=约标答疑,2=发标澄清,3=开标澄清,4=评标澄清
export const QA_BIDDING = 1;
export const QA_SEND = 2;
export const QA_OPEN = 3;
export const QA_COMMEND = 4;




/** end 问答阶段常量 end*/

export const PROJECT_URL = 'http://project.china-forest.com'; //百大项目库
export const TENDER_URL = 'http://tender.china-forest.com'; //招投标
export const EXPERT_URL = 'http://expert.china-forest.com'; //专家智库
export const MASTER_URL = 'http://www.china-forest.com'; //主平台


// export const PROJECT_URL = 'http://new_project.china-forest.com'; //百大项目库
// export const TENDER_URL = 'http://new_tender.china-forest.com'; //招投标
// export const EXPERT_URL = 'http://new_expert.china-forest.com/'; //专家智库
// export const MASTER_URL = 'http://new.china-forest.com'; //主平台