import React,{useState} from 'react';
import css from './add-project-industry.module.less';
import {Row,Col,Button} from 'antd';
import {useDataApi} from "../hook/dataApi";

export const AddProjectIndustry = (props)=>{
    const [{data ,isLoading}] = useDataApi({ url: '/api/fgetnav', method: 'post', data: { c_id: 405, type: 2},initialData: []});
    const [sub, setSub] = useState(props.value);
    const handleClick = (item)=>{
        setSub(item.id);props.onChange(item)
    };
    return (
        <div className={css.industry}>
            {!isLoading &&
            <Row  gutter={[8, 8]}  >
                {data.map((item,index)=>{
                    return  <Col span={6} key={'sub'+item.id} ><Button style={{textAlign: 'left'}} className={ sub === item.id? css.check:''} onClick={()=>handleClick(item)}>{item.name}</Button></Col>
                })}
            </Row>
            }
        </div>
    )
}