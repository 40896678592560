import React, {useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom';
import css from './SubNav.module.less';
import {AppstoreOutlined, ClusterOutlined, SettingOutlined, SoundOutlined, StarOutlined} from '@ant-design/icons';
import {Badge} from "antd";
import {useMessageNumData} from "../../../hook/messageNumData";
import {useUserInfo} from "../../../hook";


export const SubNav = (props)=>{

    const subNav = [{
        label:'关于我们',
        path:'/about/home'
    },{
        label:'服务介绍',
        path:'/about/state'
    },{
        label:'网站地图',
        path:'/about/map'
    },{
        label:'版权所有',
        path:'/about/copyright',
    },{
        label:'用户权限',
        path:'/about/authority',

    },{
        label:'意见反馈',
        path:'/about/suggestion',

    }];

    return (
        <div className={ css.navList }>
            {
                subNav.map((opt,index)=>
                <NavLink to={ opt.path }  key={ index } className={ css.nav } activeClassName={ css.selected}>
                     { opt.label }
                </NavLink>
                )
            }
        </div>
    )
}