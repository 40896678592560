import React from 'react';
import {Spin} from 'antd';
import css from './yc-loading.module.less';


export const YcLoading = (props)=>{

    return (
        <div className={ css.loading }>
            <div className={ css.spinWrap }>
                <div className={ css.spin }>
                  <Spin />
                </div>
            </div>
        </div>
    );
}