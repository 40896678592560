import React, {Fragment} from 'react';
import {Card, SubNav, UserInfo} from '../components';
import {Button, Descriptions} from 'antd';
import {useDataApi, useUserInfo} from '../../../hook';
import styles from './index.module.less';
import css from '../index.module.less';
import {UploadImage} from "../../../components";

export const Setting = ()=>{
     const [userInfo] = useUserInfo();
    const [{data}] = useDataApi({url: '/api/getAuthentication', method: 'post'});

    return (
        <div className={  css.body}>
            <div className={css.sideBar }  >
                <UserInfo />
                <SubNav />
            </div>
            <div className={ css.mainBody }>
                <Card title="基本信息" extra={<Fragment> <Button className={css.yellowBtn} href="/account/setting/mobile">修改手机号</Button><Button className={css.yellowBtn} href="/account/setting/password">修改密码</Button></Fragment>}>
                    <div className={ styles.items }>
                        <Descriptions column={1}>
                            <Descriptions.Item label="用户名">{userInfo.nickname}</Descriptions.Item>
                            <Descriptions.Item label="真实姓名">{userInfo.username}</Descriptions.Item>
                            <Descriptions.Item label="联系方式">{userInfo.mobile && userInfo.mobile.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2')}</Descriptions.Item>
                            <Descriptions.Item label="行业分类">{data.business_type}</Descriptions.Item>
                            {data.apply_type === 1 ? <Fragment>
                                <Descriptions.Item label="注册类别">{data.apply_state == 2?'企业':'未认证'}</Descriptions.Item>
                                <Descriptions.Item label="公司名称">{data.company_name || '未认证'}</Descriptions.Item>
                                <Descriptions.Item label="注册资金">{data.apply_state == 2? data.reg_capital +'(万元)' :'未认证'}</Descriptions.Item>
                                <Descriptions.Item label="注册地址">{data.address}</Descriptions.Item>
                                <Descriptions.Item label="企业工商营业执照">
                                    {data.apply_state == 2 ? '已认证': '未认证'}
                                </Descriptions.Item>
                            </Fragment> : <Fragment>
                                <Descriptions.Item label="注册类别">{data.apply_state == 2?'个人':'未认证'}</Descriptions.Item>
                                <Descriptions.Item label="身份证号">{data.legal_identity || '未认证'}</Descriptions.Item>
                                <Descriptions.Item label="身份证正反面">
                                    {data.legal_identity_cert ? <UploadImage disabled={true} uploadNum={2} value={data.legal_identity_cert}/> :'未上传'}
                                </Descriptions.Item>
                                <Descriptions.Item label="手持身份证照片">
                                    { data.legal_identity_cert_un ?
                                    <UploadImage disabled={true} uploadNum={1} value={data.legal_identity_cert_un}/> : '未上传'}
                                </Descriptions.Item>
                            </Fragment>}
                        </Descriptions>
                        {userInfo.apply_state != 2 &&  <Button type={"primary"} style={{marginLeft: '150px'}} href='/account/setting/userauth' >立即认证</Button>}
                    </div>
                </Card>
            </div>
        </div>
    )
}
