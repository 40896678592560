import React, {useEffect} from 'react';
import css from './hot-city-list.module.less';
import {useDataApi} from "../hook";

export const HotCityList =(props)=>{
    // const { items =[], goDetail} = props;
    const {style} = props;
    const [ {data, isLoading},updateParams ]  =useDataApi({url:'/pro/projectCount',method:'post',initialData:[]});
  const colors = [
      '#9BCB30','#4F9DFB','#FBB05C',
      '#FF999C','#8185EA','#DAE73A',
      '#5BE387','#8B888B','#2FE3EF','#DA7CD4',
  ];

    const goDetail = (opt)=>{

    };

    return (
        <div className={ css.card } style={style}>
            <div className={css.title}>
                热门城市
            </div>
            <div className={css.list}>
                {
                    data.map((opt,index)=>
                        <div className={ css.item } key={ index } onClick={()=>goDetail(opt)}>
                            <div className={ css.name } >{ opt.cname }</div>
                            <div className={css.numberWarp}>
                                <div className={css.number} style={{width: opt.total /data[0].total*100 +'%',background: colors[index] }}>{opt.total}个</div>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
}