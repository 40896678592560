import React, {useEffect, useState} from 'react';
import {Card, SubNav, UserInfo} from '../components';
import {YcPagination} from '../../../components';
import {Button} from 'antd';
import {useDataApi} from '../../../hook';
import {useHistory} from "react-router-dom";
import css from '../index.module.less';
import {PublishModal} from "../../../common";
import IconEmpty from 'aliasimgurl/icon_empty.png';

export const Workbench = () => {

    const [dataList, setDataList] = useState([]);
    const [params, setParams] = useState({});

    const [{data: dataSource, isLoading},updateParams] = useDataApi();
    useEffect(() => {
        if (!isLoading) {
            const {data = [], total} = dataSource;
            const _params = params;
            _params.total = total;
            setDataList(data);
            setParams(_params);
        }
    }, [isLoading]);
    useEffect(() => {
        const {page=1} = params;
        updateParams({
            url: '/api/user/NoticeList',
            method: 'post',
            data: {page, category:9}
        });
    }, [params]);
    const {push} = useHistory();
    const goDetail = ({project_id,id,category,type,status}) => {

        const callback = ()=>{
            skip({project_id});
        }
        markRead({category,type,status,id,callback});
    };
    const markRead = ({category,type,status,id,callback=()=>{}},index=null)=>{
        if(!status){
            PublishModal.updateNotice({project_notice_id:id}).pipe().subscribe({
                next:res=>{
                    callback();
                    if(index !== null){
                        const _dataList = dataList;
                        _dataList[index].status = 1;
                        setDataList([..._dataList]);
                    }
                },
                error:err=>{},
                complete:()=>{}
            });
        }else{
            callback();
        }
    };

    const skip = ({project_id})=>{
        push({pathname: '/account/project/list',state:{project_sn:project_id}});
    }

    return (
        <div className={css.body}>
            <div className={css.sideBar}>
                <UserInfo/>
                <SubNav/>
            </div>
            {!isLoading &&
            <div className={css.mainBody}>
                <Card title="消息通知">

                    {/*<div className={css.currentTopic}>*/}
                    {/*    <div className={css.ready_text} onClick={allReady}>全部标为已读</div>*/}
                    {/*</div>*/}
                    <div className={css.dataList}>
                        <div className={css.item}>
                            <div className={css.title}>通知</div>
                            <div className={css.time}>时间</div>
                            <div className={css.time}>状态</div>
                            <div className={css.action} style={{paddingLeft: '12px'}}>操作</div>
                        </div>
                        {
                            dataList.map((opt,index) =>
                                <div key={opt.id} className={css.item}>
                                    <div className={css.title}>{opt.content}</div>
                                    <div className={css.time}>{opt.createtime}</div>
                                    <div className={css.time} style={{color: !opt.status? '#5e82da':'#999'}}>{opt.status_text}</div>
                                     <div className={css.action}>
                                        <div onClick={()=>goDetail(opt)} className={css.btn}>查看</div>
                                        {opt.status === 0 && <a onClick={()=>markRead(opt,index)} className={css.ready_text}>标为已读</a>}

                                    </div>
                                </div>
                            )
                        }
                        {
                            !isLoading &&  !dataList.length && <div className='emptyBox'>
                                <img src={IconEmpty} alt=""/>
                                <div >暂无数据</div>
                            </div>
                        }
                        <YcPagination  total={ params.total || 0 }
                                      current={ params.page }
                                      onChange={(e)=> {const _params = params;_params.page=e; setParams({..._params})}}/>
                    </div>
                </Card>
            </div>
            }
        </div>
    )
}