import React from 'react';
import css from './fine-project-list.module.less';
import {Statistic} from "antd";

const { Countdown } = Statistic;

/** 精品项目 */
export const FineProjectList =(props)=>{

    const {items=[],goDetail} = props;
    //     项目名称                                 采购产品                     需求数量                         所属地区                     报名截止日期
    console.log("items",items);


    return (
        <div className={css.list}>
            {items.map((opt)=><div className={css.item} key={opt.id} onClick={()=>goDetail(opt)}>
                <div className={css.title}>
                   {opt.name}
                </div>
                <div className={css.money}>{opt.budget}<span>亿</span></div>
                <div className={css.detail}><span>{opt.pname}</span><span>{opt.industry_name}</span></div>
                <div className={css.btn}>查看详情</div>
            </div>)}
        </div>
    );

}