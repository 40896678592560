import React, {Fragment} from 'react';
import css from './steps.module.less';
import step_current from 'aliasimgurl/icon_step_current.png';
import step_await from 'aliasimgurl/icon_step_await.png';
import step_completed from 'aliasimgurl/icon_step_completed.png';
import classNames from 'classnames';

export const Steps = (props)=>{
    const { children,title,style,current=0 } = props;
    const steps = [{
        key: 1,
        title:'发起阶段'
        },{
            key: 2,
            title:'采购阶段'
        } ,{
             key: 3,
            title:'执行阶段'
        } ,{
            key: 4,
            title:'移交阶段'
        }];
    return (
        <div className={ css.steps } style={style}>
            {
                steps.map(opt=>
                    <div className={ css.step } key={opt.key}>
                        <img src={current > opt.key ? step_completed : current === opt.key ? step_current : step_await} alt=""/>
                        <div className={classNames(css.title,current === opt.key ? css.current:'')}>
                            {opt.title}
                        </div>

                    </div>)
            }
            <div className={css.bg} />
        </div>
    );
}