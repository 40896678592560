import React from 'react';
import css from './index.module.less';
import {TitleBox} from "../components/titleBox";
import iconAuthority from "aliasimgurl/icon_authority.png";

/** 用户权限 */
export const Authority = (props) => {
    return (
        <div className={css.body}>
            <TitleBox title={'用户权限'} />
            <div className={css.content}>
                <img src={iconAuthority} alt=""/>
            </div>
        </div>
    )
}