import React from 'react';
import classNames from 'classnames';
import css from './list.module.less';

/** 项目资讯 */
export const List =(props)=>{

    const {data:items=[],toView} = props;

    return (
        <div className={css.list} style={{minHeight: '350px'}}>
            <div className={classNames(css.item, css.tableTitle)}>
                <div className={css.title}>项目资讯</div>
                <div className={ css.time }>&nbsp;&nbsp;发布时间</div>
            </div>
            {
                items.map((opt, index) =>
                    <div className={css.item} key={index} onClick={() => toView(opt)}>
                        <div className={css.title}>{opt.title}</div>
                        <div className={ css.time }>{ opt.createtime }</div>
                    </div>
                )
            }
        </div>
    );

}