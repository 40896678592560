import React, {useEffect, useState} from 'react';
import {Card, SubNav, UserInfo} from '../components';
import {YcPagination} from '../../../components';
import classNames from 'classnames';
import {Form, message} from 'antd';
import {useDataApi} from '../../../hook';
import css from './collect.module.less';
import {useHistory} from "react-router-dom";
import {ProModal} from "../../../common";
import {tap} from "rxjs/operators";
import IconEmpty from 'aliasimgurl/icon_empty.png';

export const Collect = () => {
    const [params, setParams] = useState({page:1,type:'7',total:0});
    const {push} = useHistory();

    const [{data: dataSource, isLoading,setData}, updateParams] = useDataApi({initialData:[]});

    useEffect(() => {
        const {project_name:search_name,type,page} =params;
        updateParams({
            url: '/api/collectList',
            method: 'post',
            data: {page, type,search_name}
        })
    }, [params]);

    const handleTypeChange = (value)=>{
        let _params = params;
            _params.type =value;
            setParams({..._params});

    };
    /** 取消收藏 */
    const handleCancelCollect = (data,index)=>{
        console.log("============",data);
        ProModal.cancelCollect({id:data.cid,type:data.type}).pipe(
            tap(res=>console.log(res))
        ).subscribe({
            next:res=>{
                message.success('取消成功',3);
                let _data = dataSource;
                    _data.list.splice(index,1);
               setData({..._data});
            },
            error:err=>{},
            complete:()=>{}
        });
    };
    const goDetail = (opt)=>{
        const {cid:id} = opt;
        if(params.type == '7'){
            push({pathname:'/obj/view/'+id});
        }else{
            push({pathname:'/info/view/'+id});
        }

    };
    return (
        <div className={css.body}>
            <div className={css.sideBar}>
                <UserInfo/>
                <SubNav/>
            </div>
            <div className={css.mainBody}>

                <Card title="收藏夹">
                    {/*<Form.Item  wrapperCol={{span:10}}>*/}
                    {/*    <Search*/}
                    {/*        placeholder="请输入关键字进行搜索"*/}
                    {/*        enterButton="搜索"*/}
                    {/*        size="large"*/}
                    {/*        onSearch={value => handleSearch(value)}*/}
                    {/*    />*/}
                    {/*</Form.Item>*/}
                    <Form.Item  label={'收藏类别'} wrapperCol={{span:20}}>
                        <div className={css.typeBox}>
                           {/* //3=新闻,4=招标百科,5=标书规范*/}
                            <div className={params.type === '7'? css.active : ''} onClick={()=>handleTypeChange('7')}>项目信息</div>
                            <div className={params.type === '6'? css.active : ''} onClick={()=>handleTypeChange('6')}>项目资讯</div>
                        </div>
                    </Form.Item>
                        <div className={classNames(css.row,css.header)}>
                            <div className={css.name}>标题</div>
                            <div className={css.time}>发布时间</div>
                            {
                                params.type == '7' ?  <div className={css.status} >项目进度</div>:  <div className={css.status} >浏览量</div>
                            }

                            <div className={css.operation}>操作</div>
                        </div>
                    <div className={css.list}>


                    {!isLoading &&  dataSource.list.map((opt,index)=>
                            <div className={classNames(css.row)}>
                                <div className={css.name}  onClick={()=>goDetail(opt)}>
                                    {opt.project_name|| '未命名'}
                                </div>
                                <div className={css.time}>{opt.createtime}</div>
                                { params.type == '7' ?    <div className={css.status}>{
                                        function (){
                                            switch (opt.stage_id) {
                                                case 1:
                                                    return '发起阶段';
                                                case 2:
                                                    return '采购阶段';
                                                case 3:
                                                    return '执行阶段';
                                                case 4:
                                                    return '移交阶段';
                                                default:
                                            }
                                        }()
                                    }</div>:  <div className={css.status}>{opt.read_num}</div>
                                }

                                <div className={css.operation}>
                                    <div className={css.btn} onClick={()=>handleCancelCollect(opt,index)}>取消收藏</div>
                                </div>
                            </div>

                    )}
                        {
                            !isLoading &&  !dataSource.list.length && <div className={'emptyBox'}>
                                <img src={IconEmpty} alt=""/>
                                <div >暂无数据</div>
                            </div>
                        }
                    <YcPagination
                        total={ params.total || 0 }
                        pageSize={15}
                        current={ params.page }
                        onChange={(e)=> {const _params = params;_params.page=e; setParams({..._params})}}
                        style={ {margin:'30px 0'} } />
                    </div>
                </Card>
            </div>
        </div>
    )
}